export enum PermissionObject {
  Request = 'transportrequest',
  Auction = 'auction',
  Transport = 'transport',
  Carrier = 'carrier',
  Address = 'address',
  Workspace = 'workspace',
  User = 'user',
  Document = 'document',
  Invitation = 'workspaceinvitation',
}

export const permissionObjectPrefix: Record<PermissionObject, string> = {
  [PermissionObject.Request]: 'logistics',
  [PermissionObject.Transport]: 'auctions',
  [PermissionObject.Auction]: 'auctions',
  [PermissionObject.Carrier]: 'directory',
  [PermissionObject.Address]: 'directory',
  [PermissionObject.Workspace]: 'directory',
  [PermissionObject.User]: 'users',
  [PermissionObject.Invitation]: 'invitations_contrib',
  [PermissionObject.Document]: 'documents',
}

export enum PermissionType {
  Create = 'add',
  Read = 'view',
  Update = 'change',
  Delete = 'delete',
}

export interface Permissions {
  create: boolean
  update: boolean
  delete: boolean
  read: boolean
}
